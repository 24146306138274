import { useCallback, useEffect, useState } from "react";
import { useDocumentMetadata } from "../../apis/hooks/useDocumentMetadata";
import {
  ErrorContainerProps,
  ErrorDisplay,
} from "../../app/errorHandling/ErrorDisplay";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { useRouteHelper } from "../../app/hooks/useRouteHelper";
import { useDocumentFlow } from "../../app/hooks/document/useDocumentFlow";
import {
  selectNewDocumentVersionIdentity,
  selectShouldRefreshDocumentMetadata,
  selectTabIndex,
  setIsLoadingDetailsMetaData,
  setShouldRefreshDocumentMetadata,
} from "../../app/slices/documentDetailsSlice";
import {
  resetDocumentMetadata,
  selectCurrentDocumentMetadata,
} from "../../app/slices/documentMetadataSlice";
import { MainDiv } from "./SC/MainDiv";
import { DocumentHeader } from "./header/DocumentHeader";
import { DetailsTab } from "./tabs/DetailsTab";
import { HistoryTab } from "./tabs/HistoryTab";
import { RelatedDocumentsTab } from "./tabs/RelatedDocumentsTab";
import { TabDiv } from "./tabs/SC/TabDiv";
import {
  resetDocumentMetadataValidation,
  setIsDraftValidationOn,
} from "../../app/slices/documentMetadataValidationSlice";
import { Workflow } from "../../models/documentList/types/workflow";
import {
  setLastFetchedAttachmentUploadInfo,
  setIsAttachmentUploadInProgress,
} from "../../app/slices/fileUploadSlice";
import { Identity } from "../../models/documentDetails/documentMetadata";
import { selectUncompletedSections } from "../../app/slices/selfHelpSlice";
import { SelfHelpPage } from "../../models/selfHelp/selfHelpSection";
import { SelfHelpPopup } from "./selfHelpPopup/SelfHelpPopup";

interface DocumentProps {
  setIsError: (isError: boolean) => void;
}

export function Document(props: DocumentProps) {
  const dispatch = useAppDispatch();
  const tabIndex = useAppSelector(selectTabIndex);
  const [mandatorySelfHelpOpen, setMandatorySelfHelpOpen] = useState(false);
  const { detailsRoute, getUrlToDocumentByIdentity } = useRouteHelper();
  const { isNewDocument, isNewDocumentVersion, isNewDocumentRevision } =
    useDocumentFlow();
  const uncompletedSections = useAppSelector(selectUncompletedSections);
  const shouldRefreshDocumentMetadata = useAppSelector(
    selectShouldRefreshDocumentMetadata
  );
  const documentMetadata = useAppSelector(selectCurrentDocumentMetadata);
  const newDocumentVersionIdentity = useAppSelector(
    selectNewDocumentVersionIdentity
  );

  const errorContainerStyles: ErrorContainerProps = {
    padding: "0",
  };

  const { isDocumentMetadataError, fetchDocumentMetadata } =
    useDocumentMetadata();

  const handleRefreshView = useCallback(() => {
    if (detailsRoute) {
      void fetchDocumentMetadata(detailsRoute, true);
    }
  }, [detailsRoute, fetchDocumentMetadata]);

  const refreshData = useCallback(
    (workflow: Workflow, documentIdentity: Identity) => {
      const identityIsNotEmpty = (identity: Identity) =>
        identity.documentNumber && identity.documentLanguageCodes.length > 0;

      if (identityIsNotEmpty(documentIdentity)) {
        const newUrl = getUrlToDocumentByIdentity({
          identity: documentIdentity,
          workflow: workflow,
        });
        void fetchDocumentMetadata(newUrl, true);
        dispatch(setShouldRefreshDocumentMetadata(false));
      }
    },
    [getUrlToDocumentByIdentity, fetchDocumentMetadata, dispatch]
  );

  useEffect(() => {
    if (shouldRefreshDocumentMetadata) {
      if (isNewDocumentVersion && newDocumentVersionIdentity) {
        refreshData(Workflow.NewDocument, newDocumentVersionIdentity);
      } else if (
        !isNewDocument &&
        !isNewDocumentRevision &&
        detailsRoute &&
        documentMetadata.workflow !== Workflow.Approval &&
        documentMetadata.workflow !== Workflow.Draft &&
        documentMetadata.workflow !== Workflow.NewRevision
      ) {
        refreshData(
          documentMetadata.workflow,
          documentMetadata.documentIdentity
        );
      } else if (detailsRoute || isNewDocument) {
        refreshData(Workflow.Draft, documentMetadata.documentIdentity);
      }
    }
  }, [
    refreshData,
    detailsRoute,
    isNewDocument,
    isNewDocumentVersion,
    shouldRefreshDocumentMetadata,
    documentMetadata,
    newDocumentVersionIdentity,
    isNewDocumentRevision,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetDocumentMetadata());
      dispatch(resetDocumentMetadataValidation());
      dispatch(setIsDraftValidationOn(false));
      dispatch(setLastFetchedAttachmentUploadInfo(undefined));
      dispatch(setIsAttachmentUploadInProgress(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (detailsRoute) {
      void fetchDocumentMetadata(detailsRoute, true);
    }
  }, [fetchDocumentMetadata, detailsRoute]);

  useEffect(() => {
    if (isNewDocumentRevision && newDocumentVersionIdentity) {
      refreshData(Workflow.NewDocument, {
        ...newDocumentVersionIdentity,
        revision: "",
      });
    }
  }, [
    refreshData,
    dispatch,
    isNewDocumentRevision,
    newDocumentVersionIdentity,
  ]);

  useEffect(() => {
    if (isNewDocument) {
      dispatch(setIsLoadingDetailsMetaData(false));
    }
  }, [dispatch, isNewDocument]);

  useEffect(() => {
    props.setIsError(isDocumentMetadataError);
  }, [props, isDocumentMetadataError]);

  useEffect(() => {
    if (
      uncompletedSections.length > 0 &&
      uncompletedSections.find(
        (u) => u.page === SelfHelpPage.Details && u.optional === false
      )
    ) {
      setMandatorySelfHelpOpen(true);
    }
  }, []); //eslint-disable-line

  return (
    <MainDiv id="details-document-main-div">
      {isDocumentMetadataError ? (
        <ErrorDisplay
          errorId={"details-document"}
          errorContainerProps={errorContainerStyles}
          errorBoxPadding="0"
          refreshFunction={handleRefreshView}
          showReloadButton={true}
          errorMessageTitle="Something went wrong"
          errorDescription="Error while loading document metadata"
          showDash={false}
        />
      ) : (
        <>
          <DocumentHeader />
          {tabIndex === 0 && (
            <TabDiv id="document-details-tab-div">
              <DetailsTab />
            </TabDiv>
          )}
          {tabIndex === 1 && (
            <TabDiv>
              <RelatedDocumentsTab />
            </TabDiv>
          )}
          {tabIndex === 2 && (
            <TabDiv>
              <HistoryTab />
            </TabDiv>
          )}
          <SelfHelpPopup
            isOpen={mandatorySelfHelpOpen}
            onClose={() => setMandatorySelfHelpOpen(false)}
          />
        </>
      )}
    </MainDiv>
  );
}
