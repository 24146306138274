import { useMemo } from "react";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { Workflow } from "../../../models/documentList/types/workflow";
import { selectIsLoadingDetailsMetaData } from "../../slices/documentDetailsSlice";
import {
  selectCanApprovePendingApproval,
  selectDocumentWorkflow,
  selectHasPendingApproval,
  selectHasOtherRevisionWithPendingApproval,
  selectDocumentSecurityLevel,
} from "../../slices/documentMetadataSlice";
import { useDocumentFlow } from "../document/useDocumentFlow";
import { useAppSelector } from "../hooks";
import { useUserApplicationRoles } from "./roles/useUserApplicationRoles";
import { useUserPermissionsInMainCategory } from "./roles/useUserPermissionsInMainCategory";
import { useDocumentState } from "../document/useDocumentState";
import { DraftType } from "../../../models/documentDetails/documentStatus";
import { DocumentSecurityLevel } from "../../../models/documentSecurityLevel";

export interface PermissionsEditableState {
  isEditable: boolean;
  lockMessage?: TooltipMsg;
}

export function useUserPermissionsInDocument(
  isApprovalCategory: boolean | undefined
) {
  const arePermissionsLoading = useAppSelector(selectIsLoadingDetailsMetaData);
  const hasPendingApproval = useAppSelector(selectHasPendingApproval);
  const hasPendingApprovalInAnyRevision = useAppSelector(
    selectHasOtherRevisionWithPendingApproval
  );
  const canApprovePendingApproval = useAppSelector(
    selectCanApprovePendingApproval
  );
  const { isDocumentFromServiceIntegration } = useDocumentFlow();
  const { isFromCompany, isSuperAdmin } = useUserApplicationRoles();
  const {
    canEditDocument,
    canEditDocumentIdentity,
    canEditServiceDocument,
    canApproveDocument,
    canDeleteDocument,
  } = useUserPermissionsInMainCategory();
  const { documentDraftType } = useDocumentState();

  const workflow = useAppSelector(selectDocumentWorkflow);
  const securityLevel = useAppSelector(selectDocumentSecurityLevel);
  const isEditBlockedForApproval = useMemo(
    () =>
      isApprovalCategory &&
      workflow === Workflow.Approval &&
      !canApproveDocument,
    [isApprovalCategory, workflow, canApproveDocument]
  );

  const isEditBlockedForPendingApproval = useMemo(
    () =>
      (!canApprovePendingApproval && hasPendingApproval) ||
      (hasPendingApprovalInAnyRevision && !canApproveDocument),
    [
      canApprovePendingApproval,
      hasPendingApproval,
      hasPendingApprovalInAnyRevision,
      canApproveDocument,
    ]
  );

  const hasPermissionToEditDocument = useMemo((): PermissionsEditableState => {
    if (isSuperAdmin) {
      return { isEditable: true };
    }

    if (arePermissionsLoading) {
      return { isEditable: false };
    }

    if (isDocumentFromServiceIntegration && !canEditServiceDocument) {
      return {
        isEditable: false,
        lockMessage: TooltipMsg.NoAccessToDocument,
      };
    }

    if (!isFromCompany) {
      return {
        isEditable: false,
        lockMessage: TooltipMsg.NoAccessToDocument,
      };
    }

    if (documentDraftType === DraftType.NewDocumentDraft) {
      return { isEditable: true };
    }

    // this depends on permissions in main category of this document
    if (!canEditDocument) {
      return {
        isEditable: false,
        lockMessage: TooltipMsg.NoAccessToDocument,
      };
    }

    if (isEditBlockedForPendingApproval || isEditBlockedForApproval) {
      return {
        isEditable: false,
        lockMessage: TooltipMsg.CannotApprovePendingApproval,
      };
    }

    return { isEditable: true };
  }, [
    isSuperAdmin,
    arePermissionsLoading,
    isDocumentFromServiceIntegration,
    canEditServiceDocument,
    isFromCompany,
    canEditDocument,
    isEditBlockedForPendingApproval,
    isEditBlockedForApproval,
    documentDraftType,
  ]);

  const hasPermissionToEditSpecialFields =
    useMemo((): PermissionsEditableState => {
      if (isSuperAdmin) {
        return { isEditable: true };
      }

      if (arePermissionsLoading) {
        return { isEditable: false };
      }

      if (!hasPermissionToEditDocument.isEditable) {
        return hasPermissionToEditDocument;
      }

      if (canEditDocumentIdentity) {
        return { isEditable: true };
      }

      if (documentDraftType === DraftType.NewDocumentDraft) {
        return { isEditable: true };
      }

      return {
        isEditable: false,
        lockMessage: TooltipMsg.NoPermission,
      };
    }, [
      arePermissionsLoading,
      canEditDocumentIdentity,
      hasPermissionToEditDocument,
      isSuperAdmin,
      documentDraftType,
    ]);

  const hasPermissionToDownloadAttachment = useMemo(() => {
    if (
      securityLevel === DocumentSecurityLevel.ExternalPublicAndInternalPublic ||
      securityLevel === DocumentSecurityLevel.InternalPublic ||
      securityLevel ===
        DocumentSecurityLevel.ExternalLimitedAndInternalPublic ||
      securityLevel ===
        DocumentSecurityLevel.HiddenFromExternalAndInternalPublic
    ) {
      return true;
    }

    if (isSuperAdmin) {
      return true;
    }

    if (!isFromCompany) {
      return false;
    }

    return canEditDocument;
  }, [securityLevel, isSuperAdmin, isFromCompany, canEditDocument]);

  const hasPermissionToApproveDocument = useMemo(() => {
    return isSuperAdmin || canApproveDocument;
  }, [canApproveDocument, isSuperAdmin]);

  const hasPermissionToBreakDocumentLink = useMemo(() => {
    if (workflow !== Workflow.Published && workflow !== Workflow.Archived) {
      return { isEditable: false, lockMessage: TooltipMsg.BreakDocumentLink };
    }

    if (isEditBlockedForPendingApproval && (canEditDocument || isSuperAdmin)) {
      return {
        isEditable: false,
        lockMessage: TooltipMsg.CannotApprovePendingApproval,
      };
    }

    if (isSuperAdmin) {
      return { isEditable: true };
    }

    return {
      isEditable: canEditDocument,
      lockMessage: !canEditDocument ? TooltipMsg.BreakDocumentLink : undefined,
    };
  }, [
    workflow,
    isSuperAdmin,
    isEditBlockedForPendingApproval,
    canEditDocument,
  ]);

  const hasPermissionToDeleteDocument = useMemo(() => {
    if (isEditBlockedForPendingApproval) {
      return {
        isEditable: false,
        lockMessage: TooltipMsg.CannotApprovePendingApproval,
      };
    }

    if (isSuperAdmin || canDeleteDocument) {
      return { isEditable: true };
    }

    return {
      isEditable: false,
      lockMessage: TooltipMsg.DeleteDocument,
    };
  }, [isSuperAdmin, canDeleteDocument, isEditBlockedForPendingApproval]);

  const hasPermissionToUndeleteDocument = useMemo(() => {
    if (workflow !== Workflow.Deleted) {
      return { isEditable: false, lockMessage: TooltipMsg.UndeleteDocument };
    }

    if (isEditBlockedForPendingApproval) {
      return {
        isEditable: false,
        lockMessage: TooltipMsg.CannotApprovePendingApproval,
      };
    }

    if (isSuperAdmin || canDeleteDocument) {
      return { isEditable: true };
    }

    return {
      isEditable: false,
      lockMessage: TooltipMsg.UndeleteDocument,
    };
  }, [
    workflow,
    isSuperAdmin,
    canDeleteDocument,
    isEditBlockedForPendingApproval,
  ]);

  return {
    hasPermissionToEditDocument,
    hasPermissionToApproveDocument,
    hasPermissionToEditSpecialFields,
    hasPermissionToDownloadAttachment,
    hasPermissionToBreakDocumentLink,
    hasPermissionToDeleteDocument,
    hasPermissionToUndeleteDocument,
    isEditBlockedForPendingApproval,
    isEditBlockedForApproval,
  };
}
