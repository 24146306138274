import { useCallback, useEffect, useState } from "react";
import { useLazyIsAvailableNewRevisionQuery } from "../../../apis/documentDetailsApi";
import { Identifier } from "../../../models/documentDetails/documentMetadata";
import { useRouteHelper } from "../useRouteHelper";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { useUserApplicationRoles } from "../permissions/roles/useUserApplicationRoles";

interface IsNewRevisionAvailableProps {
  hasPendingApprovals: boolean;
}

export function useIsNewRevisionAvailable(props: IsNewRevisionAvailableProps) {
  const { getRevisionAvailableUrlToDocumentByIdentity } = useRouteHelper();
  const [isNewRevisionAvailable, setIsNewRevisionAvailable] = useState(false);
  const { isPublisher } = useUserApplicationRoles();

  const [
    isAvailableNewRevision,
    { data: isAvailableNewRevisionResponse, isError: isError },
  ] = useLazyIsAvailableNewRevisionQuery();

  const checkUserCanCreateNewRevision = useCallback(
    (documentIdentity: Identifier) => {
      const revisionAvailableUrl =
        getRevisionAvailableUrlToDocumentByIdentity(documentIdentity);
      void isAvailableNewRevision(`${revisionAvailableUrl}`);
    },
    [isAvailableNewRevision, getRevisionAvailableUrlToDocumentByIdentity]
  );

  const getNewRevisionButtonDisableTooltip = useCallback(() => {
    if (!isPublisher) {
      return TooltipMsg.CantPublish;
    }

    if (props.hasPendingApprovals) {
      return TooltipMsg.RevisionHasPendingApprovals;
    }

    return !isNewRevisionAvailable
      ? TooltipMsg.AllRevisionsHaveBeenUsed
      : TooltipMsg.NoAccessToDocument;
  }, [isNewRevisionAvailable, isPublisher, props.hasPendingApprovals]);

  useEffect(() => {
    if (isError) {
      setIsNewRevisionAvailable(false);
    }
    if (isAvailableNewRevisionResponse) {
      setIsNewRevisionAvailable(isAvailableNewRevisionResponse);
    }
  }, [isAvailableNewRevisionResponse, isError]);

  return {
    checkUserCanCreateNewRevision,
    getNewRevisionButtonDisableTooltip,
    isNewRevisionAvailable,
  };
}
