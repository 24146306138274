import { useMemo } from "react";
import { useDocumentFlow } from "../../../app/hooks/document/useDocumentFlow";
import { useAppSelector } from "../../../app/hooks/hooks";
import { PermissionsEditableState } from "../../../app/hooks/permissions/useUserPermissionsInDocument";
import { selectUserEmail } from "../../../app/slices/authenticationSlice";
import { selectIsWindowTooSmall } from "../../../app/slices/commonSlice";
import {
  selectDocumentStatus,
  selectHasPendingApproval,
  selectHasOtherRevisionWithPendingApproval,
} from "../../../app/slices/documentMetadataSlice";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { DiscardChangesButton } from "./buttons/DiscardChangesButton";
import { GoToLatestRevisionButton } from "./buttons/GoToLatestRevisionButton";
import { PublishButton } from "./buttons/publish/PublishButton";
import { SaveAsDraftButton } from "./buttons/SaveAsDraftButton";
import { SendForApprovalButton } from "./buttons/SendForApprovalButton";

interface EditedDocumentButtonsProps {
  isRequiredApprovalMissing: boolean;
  hasPermissionToEditDocument: PermissionsEditableState;
  hasCategoriesWithoutApproval: boolean;
  isEditBlockedForPendingApproval: boolean;
  hasAccessToAnyDocumentCategory: boolean;
  isDataFetching: boolean;
}

export function EditedDocumentButtons(props: EditedDocumentButtonsProps) {
  const isWindowToSmall = useAppSelector(selectIsWindowTooSmall);
  const documentStatus = useAppSelector(selectDocumentStatus);
  const userEmail = useAppSelector(selectUserEmail);
  const hasPendingApproval = useAppSelector(selectHasPendingApproval);
  const hasAnyRevisionWithPendingApproval = useAppSelector(
    selectHasOtherRevisionWithPendingApproval
  );
  const { isNewDocument, isLatestRevision } = useDocumentFlow();

  const canPublish = useMemo(() => {
    if (props.isRequiredApprovalMissing) {
      return { isAllowed: false, tooltipMsg: TooltipMsg.NoApproverRights };
    }

    if (props.hasCategoriesWithoutApproval) {
      return {
        isAllowed: false,
        tooltipMsg: TooltipMsg.CategoriesWithoutApproval,
      };
    }

    return { isAllowed: true };
  }, [props.isRequiredApprovalMissing, props.hasCategoriesWithoutApproval]);

  const canSaveAsDraft = useMemo(() => {
    if (props.isDataFetching) {
      return { isAllowed: false };
    }

    if (props.isRequiredApprovalMissing) {
      return { isAllowed: false, tooltipMsg: TooltipMsg.NoApproverRights };
    }

    if (
      hasAnyRevisionWithPendingApproval &&
      (hasPendingApproval || isLatestRevision)
    ) {
      return { isAllowed: false, tooltipMsg: TooltipMsg.PendingApproval };
    }

    return { isAllowed: true };
  }, [
    props.isDataFetching,
    hasPendingApproval,
    props.isRequiredApprovalMissing,
    hasAnyRevisionWithPendingApproval,
    isLatestRevision,
  ]);

  const navigateToLastRevision = !documentStatus.isLatest && (
    <GoToLatestRevisionButton
      latestRevision={documentStatus.latestRevision}
      isCompactView={isWindowToSmall}
    />
  );

  return (
    <>
      {props.isRequiredApprovalMissing &&
        props.hasPermissionToEditDocument.isEditable && (
          <SendForApprovalButton
            disabled={props.isEditBlockedForPendingApproval}
            disabledTooltipText={TooltipMsg.CannotApprovePendingApproval}
          />
        )}
      {props.hasPermissionToEditDocument.isEditable &&
        !documentStatus.isFromServiceIntegration && (
          <PublishButton
            id="document-details-publish-button"
            color="primary"
            userEmail={userEmail}
            text="Publish"
            disabled={!canPublish.isAllowed}
            disabledTooltipText={canPublish.tooltipMsg}
          />
        )}
      {(props.hasPermissionToEditDocument.isEditable ||
        props.hasAccessToAnyDocumentCategory) &&
        !isNewDocument && (
          <PublishButton
            id="publish-do-not-change-publisher"
            color="secondary"
            userEmail=""
            text="Publish do not change publisher"
            disabled={!canPublish.isAllowed}
            disabledTooltipText={canPublish.tooltipMsg}
          />
        )}
      <SaveAsDraftButton
        disabled={!canSaveAsDraft.isAllowed}
        disabledTooltipText={canSaveAsDraft.tooltipMsg}
      />
      <DiscardChangesButton />
      {navigateToLastRevision}
    </>
  );
}
