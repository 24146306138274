import { find } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDocumentFlow } from "../../../../app/hooks/document/useDocumentFlow";
import { useAppSelector } from "../../../../app/hooks/hooks";
import { initialEditableState } from "../../../../app/hooks/permissions/models/state";
import { useUserCanEditDocument } from "../../../../app/hooks/permissions/useUserCanEditDocument";
import { useUserPermissionsInCategory } from "../../../../app/hooks/permissions/useUserPermissionsInCategory";
import {
  selectCurrentDocumentCategories,
  selectDocumentCategories,
  selectDocumentMainCategory,
  selectDocumentWorkflow,
  selectHasPendingApproval,
  selectIsRevisionLatest,
} from "../../../../app/slices/documentMetadataSlice";
import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import {
  Category,
  CategoryPathMetadata,
} from "../../../../models/documentDetails/documentMetadata";
import { Workflow } from "../../../../models/documentList/types/workflow";
import { BreadcrumbServiceDocumentIcon } from "./SC/BreadcrumbServiceDocumentIcon";
import { CopyPathButton } from "./actions/CopyPathButton";
import { DeleteCategoryButton } from "./actions/DeleteCategoryButton";
import { Breadcrumb } from "./breadcrumb/Breadcrumb";
import { CategoryMainChip } from "./breadcrumb/CategoryMainChip";
import { CategoryPopularChip } from "./breadcrumb/CategoryPopularChip";
import { CategoryBreadcrumbButtonsDiv } from "./breadcrumb/SC/CategoryBreadcrumbButtonsDiv";
import { CategoryBreadcrumbDiv } from "./breadcrumb/SC/CategoryBreadcrumbDiv";
import { CategoryBreadcrumbEndAdornmentDiv } from "./breadcrumb/SC/CategoryBreadcrumbEndAdornmentDiv";

interface CategoryControlProps {
  categoryId: string;
  categories: CategoryPathMetadata[];
}

export function CategoryControl(props: CategoryControlProps) {
  const [isButtonsVisible, setIsButtonsVisible] = useState(false);
  const { isNewDocument } = useDocumentFlow();
  const currentDocumentCategories = useAppSelector(
    selectCurrentDocumentCategories
  );
  const mainCategory = useAppSelector(selectDocumentMainCategory);
  const documentCategories = useAppSelector(selectDocumentCategories);
  const isLatestRevision = useAppSelector(selectIsRevisionLatest);
  const [category, setCategory] = useState<Category>({
    cid: "",
    isMain: false,
    isPrioritized: false,
    isFromServiceIntegration: false,
    isDeleted: false,
  });

  const {
    canEditCategory,
    canEditPopularStatus,
    hasAccessToCategory,
    canApproveInCategory,
    isCategoryApprovalRequired,
  } = useUserPermissionsInCategory(category.cid);
  const {
    canApproveInCategory: canApproveInMainCategory,
    isCategoryApprovalRequired: isMainCategoryApprovalRequired,
  } = useUserPermissionsInCategory(mainCategory?.cid);

  const [editableState, setEditableState] = useState(initialEditableState);
  const { canEditDocument } = useUserCanEditDocument();
  const { isDeletedDocumentView } = useDocumentFlow();
  const hasPendingApproval = useAppSelector(selectHasPendingApproval);
  const workflow = useAppSelector(selectDocumentWorkflow);

  const isMainCategoryChangeBlocked = useMemo(() => {
    if (!isCategoryApprovalRequired || category.isMain) {
      return false;
    }

    return (
      !canApproveInCategory &&
      (workflow === Workflow.Approval || hasPendingApproval)
    );
  }, [
    hasPendingApproval,
    workflow,
    canApproveInCategory,
    isCategoryApprovalRequired,
    category.isMain,
  ]);

  const canChangeApprovalMainCategory = useMemo(() => {
    if (workflow === Workflow.NewDocument || category.isMain) {
      return true;
    }

    if (
      workflow === Workflow.NewRevision &&
      (canApproveInCategory || !isCategoryApprovalRequired) &&
      (canApproveInMainCategory || !isMainCategoryApprovalRequired)
    ) {
      return true;
    }

    return !isMainCategoryApprovalRequired;
  }, [
    category.isMain,
    workflow,
    canApproveInCategory,
    isCategoryApprovalRequired,
    canApproveInMainCategory,
    isMainCategoryApprovalRequired,
  ]);

  useEffect(() => {
    setEditableState(canEditDocument(false));
  }, [canEditDocument]);

  useEffect(() => {
    const category = find(currentDocumentCategories, ["cid", props.categoryId]);
    const categoryPath = find(props.categories, [
      "categoryId",
      props.categoryId,
    ]);
    if (category && categoryPath) {
      setCategory({
        ...category,
        isDeleted: categoryPath.isDeleted,
      });
    }
  }, [currentDocumentCategories, props.categories, props.categoryId]);

  const mouseEnter = () => {
    setIsButtonsVisible(true);
  };

  const mouseLeave = () => {
    setIsButtonsVisible(false);
  };

  const isEdited = () => {
    if (
      documentCategories.length > 0 &&
      find(documentCategories, {
        cid: category.cid,
        isMain: category.isMain,
        isPrioritized: category.isPrioritized,
        isFromServiceIntegration: category.isFromServiceIntegration,
      }) === undefined
    ) {
      return true;
    }
    return false;
  };

  return (
    <CategoryBreadcrumbDiv
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      data-cid={props.categoryId}
      data-gray={!canEditCategory.isEditable}
      data-locked={category.isFromServiceIntegration}
      id="details-section-categories-categories-breadcrumb"
    >
      <CategoryBreadcrumbButtonsDiv>
        <CategoryMainChip
          categoryId={props.categoryId}
          isMain={category.isMain}
          canEditCategory={canEditCategory.isEditable}
          canEditDocument={editableState.isEditable}
          isDeleted={category.isDeleted}
          isLatestRevision={isLatestRevision || isNewDocument}
          disabledTooltip={
            !canEditCategory.isEditable
              ? canEditCategory.lockMessage
              : undefined
          }
          isEditBlockedForApproval={isMainCategoryChangeBlocked}
          canChangeApprovalMainCategory={canChangeApprovalMainCategory}
        />
        <CategoryPopularChip
          isPopular={category.isPrioritized}
          categoryId={props.categoryId}
          isDeleted={category.isDeleted}
          canEditCategory={
            canEditPopularStatus.isEditable &&
            !category.isDeleted &&
            !isDeletedDocumentView
          }
          isLatestRevision={isLatestRevision || isNewDocument}
          disabledTooltip={
            isDeletedDocumentView
              ? TooltipMsg.DeletedDocument
              : !canEditPopularStatus.isEditable
                ? canEditPopularStatus.lockMessage
                : undefined
          }
        />
      </CategoryBreadcrumbButtonsDiv>
      <Breadcrumb
        isGray={!hasAccessToCategory.isEditable}
        isEdited={isEdited()}
        separator="›"
        categoryId={category.cid}
        categories={[...props.categories].reverse().slice(1)}
      />

      <CategoryBreadcrumbEndAdornmentDiv>
        {category.isFromServiceIntegration && (
          <TooltipTop title={TooltipMsg.CategoryFromExternalApp}>
            <BreadcrumbServiceDocumentIcon />
          </TooltipTop>
        )}
        <CopyPathButton
          isVisible={isButtonsVisible}
          categories={props.categories}
        />

        <DeleteCategoryButton
          isVisible={isButtonsVisible}
          hasAccess={canEditCategory.isEditable}
          isMainCategory={category.isMain}
          isLatestRevision={isLatestRevision || isNewDocument}
          categoryId={category.cid}
          disabledTooltip={
            isDeletedDocumentView
              ? TooltipMsg.DeletedDocument
              : !canEditCategory.isEditable
                ? canEditCategory.lockMessage
                : undefined
          }
        />
      </CategoryBreadcrumbEndAdornmentDiv>
    </CategoryBreadcrumbDiv>
  );
}
