import { StyledCategoryChip } from "../SC/StyledCategoryChip";
import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import { setEditedMainCategory } from "../../../../../app/slices/documentMetadataSlice";
import { useAppDispatch } from "../../../../../app/hooks/hooks";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";
import { setShouldRefreshDocumentCma } from "../../../../../app/slices/documentDetailsSlice";
import { useMemo } from "react";

interface CategoryMainChipProps {
  categoryId: string;
  isMain?: boolean;
  canEditCategory: boolean;
  canEditDocument: boolean;
  isDeleted: boolean;
  isLatestRevision: boolean;
  canChangeApprovalMainCategory?: boolean;
  onClick?: () => void;
  disabledTooltip?: string;
  isEditBlockedForApproval: boolean;
}

export function CategoryMainChip(props: CategoryMainChipProps) {
  const dispatch = useAppDispatch();
  const isDisabled = useMemo(() => {
    return (
      !props.canEditDocument ||
      !props.canEditCategory ||
      props.isDeleted ||
      props.isEditBlockedForApproval ||
      !props.canChangeApprovalMainCategory
    );
  }, [
    props.canEditCategory,
    props.canEditDocument,
    props.isDeleted,
    props.isEditBlockedForApproval,
    props.canChangeApprovalMainCategory,
  ]);

  const tooltipText = () => {
    if (props.disabledTooltip) {
      return props.disabledTooltip;
    }

    if (props.isMain) {
      return TooltipMsg.MainCategory;
    }

    if (!props.canEditDocument) return TooltipMsg.NoAccessToDocument;
    if (!props.isLatestRevision) return TooltipMsg.NotLatestRevision;
    if (!props.canEditCategory) return TooltipMsg.NoAccessToMainCategory;
    if (props.isDeleted) return TooltipMsg.CannotSetMainForDeletedCategory;
    if (props.isEditBlockedForApproval)
      return TooltipMsg.CategoryWithoutApproval;

    if (!props.canChangeApprovalMainCategory) {
      return TooltipMsg.MainCategoryRequiresApproval;
    }

    return TooltipMsg.SetMainCategory;
  };

  const handleMainCategoryChange = () => {
    dispatch(setEditedMainCategory(props.categoryId));
    dispatch(setShouldRefreshDocumentCma(true));
  };

  const className = props.isMain
    ? "main-category-chip-enabled"
    : "main-category-chip";

  return (
    <TooltipTop title={tooltipText()}>
      <StyledCategoryChip
        onClick={() => {
          if (!isDisabled) {
            handleMainCategoryChange();
          }
        }}
        data-selected={props.isMain}
        $isSelected={props.isMain === true}
        $isDisabled={isDisabled}
        size="small"
        type="main"
        label="M"
        selected={props.isMain === true}
        className={className}
      />
    </TooltipTop>
  );
}
