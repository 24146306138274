import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PreviousRevisionState } from "../../../../apis/documentActionsApi";
import usePreviousNonDeletedRevision from "../../../../app/hooks/document/usePreviousNonDeletedRevision";
import useSetStateDocumentAction from "../../../../app/hooks/document/useSetStateDocumentAction";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { useUserPermissionsInDocument } from "../../../../app/hooks/permissions/useUserPermissionsInDocument";
import {
  setIsChangingWorkflow,
  setIsUpdatingDocument,
  setShouldRefreshDocumentMetadata,
  setUsePrompt,
} from "../../../../app/slices/documentDetailsSlice";
import {
  selectDocumentIdentity,
  selectDocumentStatus,
  selectIsRevisionLatest,
} from "../../../../app/slices/documentMetadataSlice";
import { SpeedButtonControl } from "../../../../controls/Buttons/SpeedButtonControl";
import { Workflow } from "../../../../models/documentList/types/workflow";
import { Option } from "../../../../models/option";
import { WorkflowChangeType } from "../../../../models/workflowChangeType";
import { DeletePopup } from "../../deletePopup/DeletePopup";
import { ButtonDiv } from "../SC/ButtonDiv";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import {
  addVisibleButton,
  ActionButton,
  removeVisibleButton,
  selectDetailsHasRequiredUncompletedSections,
} from "../../../../app/slices/selfHelpSlice";
import { useUserApplicationRoles } from "../../../../app/hooks/permissions/roles/useUserApplicationRoles";

interface SetStateButtonProps {
  workflow: Workflow;
  disabled?: boolean;
  disabledTooltipText?: string;
  isCompactView?: boolean;
}
const notLatestRevisionText =
  "You can only set latest revision of document as published";

export function SetStateButton(props: SetStateButtonProps) {
  const dispatch = useAppDispatch();
  const documentStatus = useAppSelector(selectDocumentStatus);
  const options: Option[] = [];
  const {
    hasPermissionToEditDocument,
    hasPermissionToDeleteDocument,
    hasPermissionToUndeleteDocument,
  } = useUserPermissionsInDocument(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const isLatestRevision = useAppSelector(selectIsRevisionLatest);
  const identity = useAppSelector(selectDocumentIdentity);
  const [previousRevisionState, setPreviousRevisionsState] = useState<
    PreviousRevisionState | undefined
  >();
  const hasUncompletedSections = useAppSelector(
    selectDetailsHasRequiredUncompletedSections
  );
  const { isPublisher } = useUserApplicationRoles();

  const onWorkflowChangeLoading = useCallback(() => {
    dispatch(setUsePrompt(false));
    dispatch(setIsUpdatingDocument(true));
    dispatch(setIsChangingWorkflow(true));
  }, [dispatch]);

  const onWorkflowChangeError = useCallback(() => {
    dispatch(setUsePrompt(true));
    dispatch(setIsUpdatingDocument(false));
    dispatch(setIsChangingWorkflow(false));
  }, [dispatch]);

  const onWorkflowChangeFinish = useCallback(() => {
    dispatch(setIsUpdatingDocument(false));
    dispatch(setIsChangingWorkflow(false));
  }, [dispatch]);

  const onWorkflowChangeCompleted = useCallback(() => {
    dispatch(setShouldRefreshDocumentMetadata(true));
  }, [dispatch]);

  const { changeDocumentWorkflow } = useSetStateDocumentAction({
    onWorkflowChangeLoading,
    onWorkflowChangeError,
    onWorkflowChangeFinish,
    onWorkflowChangeCompleted,
  });

  const onFetchPreviousNonDeletedRevisionSuccess = useCallback(
    (revisionState: PreviousRevisionState) => {
      if (revisionState.canPreviousRevisionBePublished) {
        setIsPopupOpen(true);
        setPreviousRevisionsState(revisionState);
      } else {
        changeDocumentWorkflow(WorkflowChangeType.Delete);
      }
    },
    [changeDocumentWorkflow]
  );

  const { fetchPreviousNonDeletedRevision } = usePreviousNonDeletedRevision({
    identity: identity,
    onSuccess: onFetchPreviousNonDeletedRevisionSuccess,
  });

  if (props.workflow === Workflow.Deleted) {
    options.push({
      text: "Published",
      hasAccess:
        hasPermissionToUndeleteDocument.isEditable &&
        documentStatus.canChangeToPublish &&
        hasPermissionToEditDocument.isEditable,
      description: "Set document workflow to published",
      disabledTooltip: !documentStatus.canChangeToPublish
        ? TooltipMsg.WillNotBeLatestRevisionText
        : hasPermissionToEditDocument.lockMessage ??
        hasPermissionToUndeleteDocument.lockMessage,
      onClick: () => {
        changeDocumentWorkflow(WorkflowChangeType.Publish);
      },
    });
    options.push({
      text: "Archived",
      hasAccess:
        hasPermissionToUndeleteDocument.isEditable &&
        hasPermissionToEditDocument.isEditable,
      disabledTooltip:
        hasPermissionToEditDocument.lockMessage ??
        hasPermissionToUndeleteDocument.lockMessage,
      description: "Set document workflow to archived",
      onClick: () => {
        changeDocumentWorkflow(WorkflowChangeType.Archive);
      },
    });
  }

  if (props.workflow === Workflow.Archived) {
    options.push({
      text: "Published",
      hasAccess:
        documentStatus.isLatest && hasPermissionToEditDocument.isEditable,
      disabledTooltip: !documentStatus.isLatest
        ? notLatestRevisionText
        : hasPermissionToEditDocument.lockMessage,
      description: "Set document workflow to published",
      onClick: () => {
        changeDocumentWorkflow(WorkflowChangeType.Publish);
      },
    });
  }

  if (props.workflow === Workflow.Published) {
    options.push({
      text: "Archived",
      hasAccess: hasPermissionToEditDocument.isEditable,
      disabledTooltip: hasPermissionToEditDocument.lockMessage,
      description: "Set document workflow to archived",
      onClick: () => {
        changeDocumentWorkflow(WorkflowChangeType.Archive);
      },
    });
  }

  if (
    props.workflow === Workflow.Published ||
    props.workflow === Workflow.Archived
  ) {
    options.push({
      text: "Deleted",
      hasAccess: hasPermissionToDeleteDocument.isEditable,
      description: "Set document workflow to deleted",
      disabledTooltip: hasPermissionToDeleteDocument.lockMessage,
      onClick: () => {
        if (isLatestRevision) {
          fetchPreviousNonDeletedRevision();
        } else {
          changeDocumentWorkflow(WorkflowChangeType.Delete);
        }
      },
    });
  }

  useEffect(() => {
    dispatch(addVisibleButton(ActionButton.SetState));

    return () => {
      dispatch(removeVisibleButton(ActionButton.SetState));
    };
  }, [dispatch]);

  const isDisabled = useMemo(() => {
    if (props.disabled || hasUncompletedSections || !isPublisher) {
      return true;
    } else {
      return false;
    }
  }, [hasUncompletedSections, isPublisher, props.disabled])

  const disableTooltipText = useMemo(() => {
    return !isPublisher ? TooltipMsg.CantPublish : hasUncompletedSections
      ? TooltipMsg.UncompletedSelfHelpSections
      : props.disabledTooltipText
  }, [hasUncompletedSections, isPublisher, props.disabledTooltipText])

  return (
    <ButtonDiv>
      <SpeedButtonControl
        colors="secondary"
        id="document-details-set-state-button"
        text="Set state"
        options={options}
        isVisible={true}
        isCompactView={props.isCompactView == true}
        disabled={isDisabled}
        disabledTooltipText={disableTooltipText}
        icon={<ChangeCircleIcon fontSize="small" />}
      />
      <DeletePopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        previousNonDeletedRevision={previousRevisionState}
        onKeepArchived={() => {
          setIsPopupOpen(false);
          changeDocumentWorkflow(WorkflowChangeType.Delete);
        }}
        onPublishPreviousRevision={() => {
          setIsPopupOpen(false);
          changeDocumentWorkflow(WorkflowChangeType.DeleteWithPublish);
        }}
        onDeleteAllRevisions={() => {
          setIsPopupOpen(false);
          changeDocumentWorkflow(WorkflowChangeType.DeleteAll);
        }}
      />
    </ButtonDiv>
  );
}
