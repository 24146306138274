import { useMemo } from "react";
import { RolesInApplication } from "../../../../models/rolesInApplication";
import { hasApplicationRole } from "../../../helpers/checkRoles";
import { selectUserRoles } from "../../../slices/authenticationSlice";
import { selectCurrentDocumentMetadata } from "../../../slices/documentMetadataSlice";
import { useAppSelector } from "../../hooks";

export function useUserApplicationRoles() {
  const myApplicationRoles = useAppSelector(selectUserRoles);
  const documentMetadata = useAppSelector(selectCurrentDocumentMetadata);

  const roles = useMemo(() => {
    return {
      isSuperAdmin: hasApplicationRole(
        RolesInApplication.SuperAdmin,
        myApplicationRoles
      ),
      isFromCompany: hasApplicationRole(
        documentMetadata.company,
        myApplicationRoles
      ),
      isPublisher: hasApplicationRole(
        RolesInApplication.Publisher,
        myApplicationRoles
      ),
    };
  }, [myApplicationRoles, documentMetadata.company]);

  return roles;
}
