import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useGetAppSettingsQuery } from "../../../../apis/configApi";
import { useLazyGetDocumentsListQuery } from "../../../../apis/documentsListApi";
import { openSnackbar } from "../../../../app/helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { useRouteHelper } from "../../../../app/hooks/useRouteHelper";
import {
  decrementGroupItemsToLoadCount,
  selectAllGroupAccordionState,
  selectAreAllGroupsExpanded,
  selectLanguageGroup,
  selectPartsGroup,
  selectSortBy,
  selectSortDescending,
  setDocumentsToLoadForExpandedPart,
  setPartsGroup,
} from "../../../../app/slices/documentListSlice";
import { checkboxMinWidth } from "../../../../app/slices/models/documentsModels";
import {
  selectIncludeDocumentsFromSubcategories,
  selectIncludeDocumentsICannotManage,
  selectNavigationFilters,
  selectShowOnlyUnCategorizedDocuments,
} from "../../../../app/slices/navigationSlice";
import DocumentListItem from "../../../../models/documentList/documentListItem";
import { DocumentPartGroup } from "../../../../models/documentList/groupedDocumentTreeResult";
import { AccordionState } from "../../../../models/documentList/types/accordionState";
import { SnackbarType } from "../../../../models/snackbar";
import { DocumentListItemSkeleton } from "../documentListItem/DocumentListItemSkeleton";
import { DocumentsListItem } from "../documentListItem/DocumentsListItem";
import { ChevronDiv } from "../documentListItem/styledComponents/ChevronDiv";
import { LoadMoreError } from "./LoadMoreError";
import { PartsAccordionError } from "./PartsAccordionError";
import { GroupCountSC } from "./styledComponents/GroupCountSC";
import { GroupNameTypography } from "./styledComponents/GroupNameTypography";
import { ShowMoreButton } from "./styledComponents/ShowMoreButton";
import { ShowMoreButtonDiv } from "./styledComponents/ShowMoreButtonDiv";
import { StyledAccordion } from "./styledComponents/StyledAccordion";
import { StyledAccordionDetails } from "./styledComponents/StyledAccordionDetails";
import { StyledAccordionSummary } from "./styledComponents/StyledAccordionSummary";
import { DocumentIdentity } from "../../../../models/documentList/documentListSearchCriteria";
import { useChangeState } from "../hooks/useChangeState";
import { SnackbarMsg } from "../../../../controls/Snackbar/SnackbarMessages";

export interface PartsAccordionProps {
  partGroup: DocumentPartGroup;
  languageGroup: string[];
  documentNumber: string;
  zebraStripe: boolean;
  languageGroupId: string;
}

export interface Page {
  pageSize: number;
  pageNumber: number;
}

export function PartsAccordion(props: PartsAccordionProps) {
  const RevisionsLoadingErrorMessage = "Document revisions loading failed!";
  const RevisionsLoadingChangeWorkflowMessage =
    "To load more, refresh the list after changing the workflow";

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectNavigationFilters);
  const { data: appSettings } = useGetAppSettingsQuery(null);
  const sortBy = useAppSelector(selectSortBy);
  const sortDescending = useAppSelector(selectSortDescending);
  const includeDocsFromSubcats = useAppSelector(
    selectIncludeDocumentsFromSubcategories
  );
  const includeDocsIcannotManage = useAppSelector(
    selectIncludeDocumentsICannotManage
  );
  const showOnlyUnCategorizedDocuments = useAppSelector(
    selectShowOnlyUnCategorizedDocuments
  );
  const allGroupAccordionState = useAppSelector(selectAllGroupAccordionState);
  const areAllGroupsExpanded = useAppSelector(selectAreAllGroupsExpanded);
  const languageGroup = useAppSelector((state) =>
    selectLanguageGroup(state, props.documentNumber, props.languageGroupId)
  );
  const partsGroup = useAppSelector((state) =>
    selectPartsGroup(
      state,
      props.documentNumber,
      props.languageGroupId,
      props.partGroup.name
    )
  );
  const pageSizeFromSettings =
    appSettings?.documentList.revisionsPageSize ?? 20;
  const [allDocumentsCount, setAllDocumentsCount] = useState(0);
  const [documentsToLoad, setDocumentsToLoad] = useState(pageSizeFromSettings);
  const [documents, setDocuments] = useState<DocumentListItem[]>([]);
  const [previousPageNumber, setPreviousPageNumber] = useState(0);
  const [workflowChanged, setWorkflowChanged] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [page, setPage] = useState<Page>({
    pageNumber: 1,
    pageSize:
      partsGroup?.documentsToLoad && partsGroup.documentsToLoad > 0
        ? props.partGroup.count > partsGroup.documentsToLoad &&
          props.partGroup.count <= pageSizeFromSettings
          ? props.partGroup.count
          : partsGroup.documentsToLoad
        : pageSizeFromSettings,
  });
  const [isLoadMoreClicked, setIsLoadMoreClicked] = useState(false);
  const { getDocumentListItemId } = useRouteHelper();

  const skeletonCount =
    props.partGroup.count > page.pageSize
      ? page.pageSize
      : props.partGroup.count;

  const [
    getDocumentsList,
    {
      data: response,
      isLoading: isDocumentsListLoading,
      isFetching: isDocumentsListFetching,
      isError: isDocumentsListError,
    },
  ] = useLazyGetDocumentsListQuery();

  const { refreshList, isRefreshDocumentListFetching } = useChangeState();

  const reloadAfterChangeWorkflow = (document: DocumentIdentity) => {
    refreshList(document, documents, setDocuments, true, setAllDocumentsCount);
    setWorkflowChanged(true);
  };

  const onExpandedToggle = useCallback(
    (expand: boolean) => {
      const partId = {
        documentNumber: props.documentNumber,
        languageGroupId: props.languageGroupId,
        partGroupId: props.partGroup.name,
      };

      if (expand) {
        dispatch(
          setPartsGroup({
            ...partId,
            expandedPart: {
              isExpanded: true,
              documentsToLoad: documents.length,
              shouldExpandOnExpandAll: documents.length > 0,
            },
          })
        );
      } else {
        dispatch(
          setPartsGroup({
            ...partId,
            expandedPart: {
              isExpanded: false,
              documentsToLoad: 0,
              shouldExpandOnExpandAll: false,
            },
          })
        );
      }
    },
    [
      props.documentNumber,
      props.languageGroupId,
      props.partGroup.name,
      documents.length,
      dispatch,
    ]
  );

  useEffect(() => {
    if (
      allGroupAccordionState === AccordionState.Expanded &&
      areAllGroupsExpanded &&
      languageGroup?.isExpanded &&
      (!partsGroup?.isExpanded || documentsToLoad > 0) &&
      (partsGroup === undefined || partsGroup.shouldExpandOnExpandAll)
    ) {
      setPreviousPageNumber(0);
      setPage({ pageNumber: 1, pageSize: 10000 });
      onExpandedToggle(true);
    }
  }, [
    partsGroup,
    allGroupAccordionState,
    areAllGroupsExpanded,
    languageGroup,
    documentsToLoad,
    onExpandedToggle,
  ]);

  const reloadRevisionsList = useCallback(
    (useCache: boolean | undefined) => {
      getDocumentsList(
        {
          documentIdentity: {
            documentNumber: props.documentNumber,
            documentPart: props.partGroup.name,
            documentLanguageCodes: props.languageGroup,
          },
          ...filters,
          pageNumber: page.pageNumber,
          pageSize: page.pageSize,
          includeDocumentsFromSubcategories: includeDocsFromSubcats,
          includeDocumentsICannotManage: includeDocsIcannotManage,
          showOnlyUnCategorizedDocuments: showOnlyUnCategorizedDocuments,
          sortBy: sortBy,
          sortDescending: sortDescending,
          returnGroupedResults: false,
          documentTitle: filters.searchDocumentTitle,
          documentNumber: filters.searchDocumentNumber,
        },
        useCache
      )
        .unwrap()
        .then(() => setReloadList(false))
        .catch(() =>
          openSnackbar(
            dispatch,
            SnackbarMsg.RevisionsError,
            SnackbarType.error
          )
        );
    },
    [
      filters,
      sortBy,
      sortDescending,
      props.documentNumber,
      props.languageGroup,
      props.partGroup.name,
      includeDocsFromSubcats,
      includeDocsIcannotManage,
      showOnlyUnCategorizedDocuments,
      page.pageNumber,
      page.pageSize,
      getDocumentsList,
      dispatch,
    ]
  );

  useEffect(() => {
    if (
      page.pageNumber > 0 &&
      previousPageNumber !== page.pageNumber &&
      appSettings &&
      partsGroup?.isExpanded
    ) {
      reloadRevisionsList(true);
    }
  }, [
    page.pageNumber,
    page.pageSize,
    previousPageNumber,
    appSettings,
    partsGroup?.isExpanded,
    reloadRevisionsList,
  ]);

  useLayoutEffect(() => {
    if (response && !reloadList) {
      if (
        page.pageNumber === 1 &&
        documents.length > 0 &&
        workflowChanged === true &&
        documents.length <= response.documents.length
      ) {
        return;
      }

      setPreviousPageNumber(page.pageNumber);

      if (page.pageNumber === 1) {
        setDocuments(response.documents);
        setAllDocumentsCount(response.allGroupsCount);
        dispatch(decrementGroupItemsToLoadCount(response.documents.length));
      } else {
        const documentsToSet = response.documents.filter(
          (d) =>
            !documents.some(
              (exd) =>
                exd.revision === d.revision && exd.workflow === exd.workflow
            )
        );

        if (documentsToSet.length > 0) {
          setDocuments([...documents, ...documentsToSet]);
          setAllDocumentsCount(response.allGroupsCount);
          dispatch(decrementGroupItemsToLoadCount(response.documents.length));
        }
      }
    }
  }, [
    dispatch,
    response,
    documents,
    page.pageNumber,
    workflowChanged,
    reloadList,
  ]);

  useLayoutEffect(() => {
    const documentsToLoad = allDocumentsCount - documents.length;
    if (documentsToLoad > pageSizeFromSettings) {
      setDocumentsToLoad(pageSizeFromSettings);
    } else {
      setDocumentsToLoad(documentsToLoad);
    }
  }, [documents, allDocumentsCount, pageSizeFromSettings]);

  useEffect(() => {
    if (documents.length > 0) {
      dispatch(
        setDocumentsToLoadForExpandedPart({
          documentNumber: props.documentNumber,
          languageGroupId: props.languageGroupId,
          partGroupId: props.partGroup.name,
          documentsToLoad: documents.length,
        })
      );
    }
  }, [
    documents.length,
    props.documentNumber,
    props.languageGroupId,
    props.partGroup.name,
    dispatch,
  ]);

  return (
    <StyledAccordion
      id={"document-list-group-parts-accordion-" + props.partGroup.name}
      disableGutters={true}
      square={true}
      expanded={!!partsGroup?.isExpanded}
      onChange={() => {
        onExpandedToggle(!partsGroup?.isExpanded);
        if (!partsGroup?.isExpanded) {
          setPage({
            pageNumber: 1,
            pageSize: pageSizeFromSettings,
          });
        }
      }}
      $useBorder={false}
      $zebraStripe={props.zebraStripe}
      $isGray={false}
    >
      <StyledAccordionSummary
        $maxHeight={theme.density.compactRowHeight + "px"}
        $minHeight={theme.density.compactRowHeight + "px"}
        $paddingLeft={2 * checkboxMinWidth}
      >
        <ChevronDiv>
          {partsGroup?.isExpanded ? (
            <ExpandMoreIcon
              className="document-list-item-group-part-expand"
              fontSize="small"
            />
          ) : (
            <KeyboardArrowRightIcon
              className="document-list-item-group-part-close"
              fontSize="small"
            />
          )}
        </ChevronDiv>
        <GroupNameTypography className="document-list-item-group-part-name">
          Part: {props.partGroup.name ? props.partGroup.name : "Main"}
        </GroupNameTypography>
        <GroupCountSC className="document-list-item-group-part-count">
          {" "}
          ({props.partGroup.count}{" "}
          {props.partGroup.count === 1 ? "version" : "versions"})
        </GroupCountSC>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {isDocumentsListError && !isLoadMoreClicked && (
          <PartsAccordionError
            errorMessage={RevisionsLoadingErrorMessage}
            reloadDataHandler={reloadRevisionsList}
          />
        )}
        {(isDocumentsListLoading || isRefreshDocumentListFetching) &&
          Array(skeletonCount)
            .fill(0)
            .map((value, index) => {
              return (
                <DocumentListItemSkeleton
                  key={index}
                  zebraStripe={props.zebraStripe}
                  isResizing={false}
                  insideGroup={true}
                />
              );
            })}

        {!isRefreshDocumentListFetching &&
          !reloadList &&
          documents.map((doc, index) => {
            return (
              <DocumentsListItem
                index={index}
                key={getDocumentListItemId(doc)}
                documentListItem={doc}
                zebraStripe={props.zebraStripe}
                insideGroup={true}
                reloadAfterWorkflowChange={reloadAfterChangeWorkflow}
              />
            );
          })}

        {isDocumentsListFetching &&
          !isDocumentsListLoading &&
          Array(documentsToLoad)
            .fill(0)
            .map((value, index) => {
              return (
                <DocumentListItemSkeleton
                  key={index}
                  zebraStripe={props.zebraStripe}
                  isResizing={false}
                  insideGroup={true}
                />
              );
            })}
        <ShowMoreButtonDiv
          isVisible={
            !isDocumentsListLoading &&
            !isDocumentsListFetching &&
            !isRefreshDocumentListFetching &&
            documentsToLoad > 0
          }
          id={"add-more-revisions-button-div-" + props.documentNumber}
          className="add-more-revisions-button-div"
        >
          {isDocumentsListError || workflowChanged ? (
            <LoadMoreError
              errorMessage={
                isDocumentsListError
                  ? RevisionsLoadingErrorMessage
                  : RevisionsLoadingChangeWorkflowMessage
              }
              reloadDataHandler={() => {
                setPage((prev) => {
                  return {
                    pageSize: prev.pageSize,
                    pageNumber: 1,
                  };
                });
                reloadRevisionsList(false);
                setWorkflowChanged(false);
                setReloadList(true);
              }}
            />
          ) : (
            <ShowMoreButton
              startIcon={<ChangeCircleOutlinedIcon fontSize="inherit" />}
              variant="text"
              size="small"
              id={"add-more-revisions-button-" + props.documentNumber}
              className="add-more-revisions-button"
              onClick={() => {
                setIsLoadMoreClicked(true);
                setPage({
                  pageSize: pageSizeFromSettings,
                  pageNumber: page.pageNumber + 1,
                });
              }}
            >
              Load {documentsToLoad} More revisions
            </ShowMoreButton>
          )}
        </ShowMoreButtonDiv>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}
