import { Workflow } from "../../../../../models/documentList/types/workflow";
import { StyledMenuItem } from "../cells/styledComponents/StyledMenuItem";
import { ListItemIcon, ListItemText, Menu } from "@mui/material";
import { StyledDivider } from "./styledComponents/StyledDivider";
import ShareIcon from "@mui/icons-material/Share";

interface GroupedOptionsProps {
  workflow: Workflow;
  isMenuOpened: boolean;
  menuAnchorEl: Element | null;
  onMenuClose: (event: React.MouseEvent) => void;
  onShareUrlClick: (event: React.MouseEvent) => void;
  onNewLanguageClick: () => void;
}

export default function GroupedDocumentsOptions(props: GroupedOptionsProps) {
  return (
    <Menu
      id="basic-menu"
      anchorEl={props.menuAnchorEl}
      open={props.isMenuOpened}
      onClose={props.onMenuClose}
      className="document-options"
    >
      {props.workflow !== Workflow.Deleted && [
        <StyledMenuItem key="share-url" onClick={props.onShareUrlClick}>
          <ListItemIcon>
            <ShareIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Share URL</ListItemText>
        </StyledMenuItem>,
        <StyledDivider key="share-url-divider" />,
      ]}
      <StyledMenuItem
        key="new-language-version"
        onClick={props.onNewLanguageClick}
      >
        <ListItemIcon></ListItemIcon>
        <ListItemText>New Language version</ListItemText>
      </StyledMenuItem>
    </Menu>
  );
}
