import { useCallback, useEffect, useMemo, useState } from "react";
import { useDocumentCategoriesState } from "../../../app/hooks/document/useDocumentCategoriesState";
import { useDocumentEditedMode } from "../../../app/hooks/document/useDocumentEditedMode";
import { useDocumentFlow } from "../../../app/hooks/document/useDocumentFlow";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { useUserPermissionsInCategory } from "../../../app/hooks/permissions/useUserPermissionsInCategory";
import { useUserPermissionsInDocument } from "../../../app/hooks/permissions/useUserPermissionsInDocument";
import { selectDocumentCategoriesWithPermissions } from "../../../app/slices/categoriesSlice";
import { selectIsWindowTooSmall } from "../../../app/slices/commonSlice";
import {
  resetDetailsView,
  selectIsChangingWorkflow,
  selectIsLoadingDetailsMetaData,
  selectIsUpdatingDocument,
} from "../../../app/slices/documentDetailsSlice";
import {
  collapseAllCategoryBreadcrumbs,
  selectCurrentDocumentMainCategory,
  selectDocumentDraftType,
  selectDocumentWorkflow,
  selectIsApprovalMainCategory,
  selectNewDocumentCategories,
} from "../../../app/slices/documentMetadataSlice";
import { selectIsFileUploading } from "../../../app/slices/fileUploadSlice";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { SelfHelpButtonControl } from "../../../controls/Buttons/SelfHelpButtonControl";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { Workflow } from "../../../models/documentList/types/workflow";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { ContextMenuButtonGroupDiv } from "../../documents/documentsHeader/SC/ContextMenuButtonGroupDiv";
import { ResetView } from "../../documents/documentsHeader/documentListSettings/resetView/ResetView";
import { ApprovalDocumentButtons } from "./ApprovalDocumentButtons";
import { ArchivedDocumentButtons } from "./ArchivedDocumentButtons";
import { DeletedDocumentButtons } from "./DeletedDocumentButtons";
import { DraftDocumentButtons } from "./DraftDocumentButtons";
import { EditedDocumentButtons } from "./EditedDocumentButtons";
import { NewDocumentButtons } from "./NewDocumentButtons";
import { PublishedDocumentButtons } from "./PublishedDocumentButtons";
import { ContextMenuButtonsDiv } from "./SC/ContextMenuButtonsDiv";
import { ContextMenuDiv } from "./SC/ContextMenuDiv";
import { ButtonsSkeleton } from "./buttons/ButtonsSkeleton";
import { CloseButton } from "./buttons/CloseButton";
import { ZoomButton } from "./buttons/ZoomButton";
import { DraftType } from "../../../models/documentDetails/documentStatus";

interface DetailsContextMenuProps {
  isDataFetching: boolean;
  isError?: boolean;
}

export function DetailsContextMenu({
  isError,
  isDataFetching,
}: DetailsContextMenuProps) {
  const isWindowTooSmall = useAppSelector(selectIsWindowTooSmall);
  const dispatch = useAppDispatch();
  const workflow = useAppSelector(selectDocumentWorkflow);
  const mainCategory = useAppSelector(selectCurrentDocumentMainCategory);
  const { isInEditMode } = useDocumentEditedMode();
  const { isNewDocument, isNewDocumentRevision } = useDocumentFlow();
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );
  const isFileUploading = useAppSelector(selectIsFileUploading);
  const isUpdatingDocument = useAppSelector(selectIsUpdatingDocument);
  const isApprovalCategory = useAppSelector(selectIsApprovalMainCategory);
  const [buttons, setButtons] = useState<JSX.Element>(<ButtonsSkeleton />);
  const {
    isEditBlockedForPendingApproval,
    hasPermissionToApproveDocument,
    hasPermissionToEditDocument,
    hasPermissionToDeleteDocument,
  } = useUserPermissionsInDocument(isApprovalCategory);
  const { hasAccessToAnyDocumentCategory } = useUserPermissionsInCategory(
    mainCategory?.cid
  );
  const categoryPermissions = useAppSelector(
    selectDocumentCategoriesWithPermissions
  );
  const newDocumentCategories = useAppSelector(selectNewDocumentCategories);
  const isChangingWorkflow = useAppSelector(selectIsChangingWorkflow);
  const hasCategoriesWithoutApproval = useMemo(() => {
    return !!categoryPermissions.find(
      (x) => x.isApprovalRequired && !x.canApproveDocument
    );
  }, [categoryPermissions]);
  const draftType = useAppSelector(selectDocumentDraftType);

  const hasNewCategoriesWithoutApproval = useMemo(() => {
    return !!categoryPermissions.find(
      (x) =>
        x.isApprovalRequired &&
        !x.canApproveDocument &&
        newDocumentCategories.includes(x.cid)
    );
  }, [categoryPermissions, newDocumentCategories]);
  const { categoriesWereModified } = useDocumentCategoriesState();

  const handleResetView = () => {
    dispatch(resetDetailsView());
    dispatch(collapseAllCategoryBreadcrumbs());
  };

  const getButtons = useCallback(
    (
      workflow: Workflow,
      isEdited: boolean,
      isApprovalCategory: boolean,
      isLoading: boolean
    ) => {
      if (isLoading) {
        return <ButtonsSkeleton />;
      }

      const approvalRequired =
        isApprovalCategory && !hasPermissionToApproveDocument;

      const editedDocumentButtons = (
        <EditedDocumentButtons
          isRequiredApprovalMissing={approvalRequired}
          hasPermissionToEditDocument={hasPermissionToEditDocument}
          hasCategoriesWithoutApproval={
            draftType === DraftType.NewDocumentDraft
              ? hasCategoriesWithoutApproval
              : hasNewCategoriesWithoutApproval
          }
          isEditBlockedForPendingApproval={isEditBlockedForPendingApproval}
          hasAccessToAnyDocumentCategory={
            hasAccessToAnyDocumentCategory || categoriesWereModified
          }
          isDataFetching={isDataFetching}
        />
      );
      const newDocumentButtons = (
        <NewDocumentButtons
          approvalRequired={approvalRequired}
          hasCategoriesWithoutApproval={
            workflow === Workflow.NewRevision
              ? hasNewCategoriesWithoutApproval
              : hasCategoriesWithoutApproval
          }
          isNewDocumentRevision={isNewDocumentRevision}
        />
      );

      switch (workflow) {
        case Workflow.NewRevision: {
          if (isEdited) return editedDocumentButtons;
          return newDocumentButtons;
        }
        case Workflow.NewDocument: {
          return newDocumentButtons;
        }
        case Workflow.Published: {
          if (isEdited) return editedDocumentButtons;
          return (
            <PublishedDocumentButtons
              hasPermissionToEditDocument={hasPermissionToEditDocument}
            />
          );
        }
        case Workflow.Draft: {
          if (isEdited) return editedDocumentButtons;
          return (
            <DraftDocumentButtons
              hasPermissionToEditDocument={hasPermissionToEditDocument}
              hasCategoriesWithoutApproval={
                draftType === DraftType.NewDocumentDraft
                  ? hasCategoriesWithoutApproval
                  : hasNewCategoriesWithoutApproval
              }
              isEditBlockedForPendingApproval={isEditBlockedForPendingApproval}
              approvalRequired={approvalRequired}
              isNewDocument={isNewDocument}
            />
          );
        }
        case Workflow.Archived: {
          if (isEdited) return editedDocumentButtons;
          return (
            <ArchivedDocumentButtons
              hasPermissionToEditDocument={hasPermissionToEditDocument}
            />
          );
        }
        case Workflow.Deleted: {
          return (
            <DeletedDocumentButtons
              hasPermissionToEditDocument={hasPermissionToEditDocument}
              hasPermissionToDeleteDocument={hasPermissionToDeleteDocument}
            />
          );
        }
        case Workflow.Approval: {
          return (
            <ApprovalDocumentButtons
              hasPermissionToApproveDocument={hasPermissionToApproveDocument}
              hasCategoriesWithoutApproval={
                draftType === DraftType.NewDocumentApproval
                  ? hasCategoriesWithoutApproval
                  : hasNewCategoriesWithoutApproval
              }
              approvalRequired={approvalRequired}
              isApprovalCategory={isApprovalCategory}
              hasAccessToAnyDocumentCategory={
                hasAccessToAnyDocumentCategory || categoriesWereModified
              }
              hasPermissionToEditDocument={hasPermissionToEditDocument}
              isEdited={isEdited}
            />
          );
        }
      }
    },
    [
      hasAccessToAnyDocumentCategory,
      hasCategoriesWithoutApproval,
      hasPermissionToApproveDocument,
      hasPermissionToDeleteDocument,
      hasPermissionToEditDocument,
      isDataFetching,
      isEditBlockedForPendingApproval,
      isNewDocument,
      isNewDocumentRevision,
      categoriesWereModified,
      hasNewCategoriesWithoutApproval,
      draftType,
    ]
  );

  useEffect(() => {
    if (!isUpdatingDocument || isChangingWorkflow) {
      setButtons(
        getButtons(
          workflow,
          isInEditMode,
          !!isApprovalCategory,
          isLoadingDetailsMetaData
        )
      );
    }
  }, [
    getButtons,
    workflow,
    isInEditMode,
    isApprovalCategory,
    isNewDocument,
    isLoadingDetailsMetaData,
    isUpdatingDocument,
    isChangingWorkflow,
  ]);

  return (
    <ContextMenuDiv>
      {isError ? (
        <ContextMenuButtonsDiv $justify="end">
          <ContextMenuButtonGroupDiv>
            <CloseButton isCompactView={isWindowTooSmall} />
          </ContextMenuButtonGroupDiv>
        </ContextMenuButtonsDiv>
      ) : (
        <ContextMenuButtonsDiv>
          <ContextMenuButtonGroupDiv id="context-menu-action-buttons">
            {buttons}
            {!isLoadingDetailsMetaData && (
              <SelfHelpButtonControl
                id={"details-header" + "-help"}
                currentSelfHelp={CurrentSelfHelp.DetailsContextMenuPublish}
                page={SelfHelpPage.Details}
                section={SectionName.ContextMenuPublish}
              />
            )}
          </ContextMenuButtonGroupDiv>
          <ContextMenuButtonGroupDiv>
            <ZoomButton isCompactView={isWindowTooSmall} />
            <ResetView
              onClickHandler={handleResetView}
              disabled={isWindowTooSmall || isFileUploading}
              isCompactView={isWindowTooSmall}
            />
            <CloseButton isDocumentEdited={isInEditMode} />
            {!isLoadingDetailsMetaData && (
              <SelfHelpButtonControl
                id={"details-header" + "-help"}
                currentSelfHelp={CurrentSelfHelp.DetailsContextMenuSection}
                page={SelfHelpPage.Details}
                section={SectionName.ContextMenuSection}
              />
            )}
          </ContextMenuButtonGroupDiv>
        </ContextMenuButtonsDiv>
      )}
    </ContextMenuDiv>
  );
}
