import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import useWindowDimensions from "../../../app/hooks/useWindowDimensions";
import { selectIsWindowTooSmall } from "../../../app/slices/commonSlice";
import {
  selectContentWidth,
  setFitToScreen,
  setNavigationWidth,
} from "../../../app/slices/contentSlice";
import {
  resetDensity,
  setAllGroupsToCollapse,
} from "../../../app/slices/documentListSlice";
import {
  fitColumnsToScreen,
  resetColumns,
} from "../../../app/slices/documentListViewSlice";
import {
  margins,
  navigationDefaultWidth,
} from "../../../app/slices/models/contentModels";
import { documentListCompactViewWidth } from "../../../app/slices/models/documentsModels";
import {
  resetNavigationSplitView,
  setIsNavigationMinimized,
} from "../../../app/slices/navigationSlice";
import { ButtonControl } from "../../../controls/Buttons/ButtonControl";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { ContextMenuButtonGroupDiv } from "./SC/ContextMenuButtonGroupDiv";
import { BulkUpdateButton } from "./documentListActions/BulkUpdateButton";
import { MetadataExportButton } from "./documentListActions/MetadataExportButton";
import { ColumnVisibility } from "./documentListSettings/columnVisibility/ColumnVisibility";
import { ExpandCollapseGroup } from "./documentListSettings/expandCollapseGroup/ExpandCollapseGroup";
import { ResetView } from "./documentListSettings/resetView/ResetView";
import { RowDensity } from "./documentListSettings/rowDensity/RowDensity";
import { useNavigate } from "react-router-dom";
import { routing } from "../../pageLayout/PageLayout";
import { useUserApplicationRoles } from "../../../app/hooks/permissions/roles/useUserApplicationRoles";
import { ContextMenuButtonGroupSpan } from "./SC/ContextMenuButtonGroupSpan";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { SelfHelpButtonControl } from "../../../controls/Buttons/SelfHelpButtonControl";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";

export function DocumentsHeader() {
  const dispatch = useAppDispatch();
  const isWindowTooSmall = useAppSelector(selectIsWindowTooSmall);
  const [isCompactView, setIsCompactView] = useState(false);
  const { windowWidth } = useWindowDimensions();
  const contentWidth = useAppSelector(selectContentWidth);
  const { isSuperAdmin, isPublisher } = useUserApplicationRoles();

  const theme = useTheme();
  const navigate = useNavigate();
  useEffect(() => {
    if (contentWidth < documentListCompactViewWidth) {
      setIsCompactView(true);
    } else {
      setIsCompactView(false);
    }
  }, [contentWidth]);

  const handleNewDocumentClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate(routing.newDocument);
  };

  const handleResetView = () => {
    const contentWidth = windowWidth - navigationDefaultWidth - margins;

    dispatch(setIsNavigationMinimized(false));
    dispatch(setFitToScreen(true));
    dispatch(resetNavigationSplitView());
    dispatch(setNavigationWidth(navigationDefaultWidth));
    dispatch(resetDensity());
    dispatch(setAllGroupsToCollapse());
    dispatch(resetColumns());
    dispatch(fitColumnsToScreen(contentWidth));
  };

  return (
    <>
      <ContextMenuButtonGroupDiv>
        <ButtonControl
          id="header-action-new-document"
          text="New document"
          disabled={!isPublisher}
          disabledTooltipText={!isPublisher ? "You can't publish any document" : undefined}
          tooltipText={TooltipMsg.NewDocument}
          colors="primary"
          hasAccess={true}
          isCompactView={false}
          height={theme.shapes.primaryButtonHeight}
          startIcon={<UploadFileIcon fontSize="small" />}
          onClick={handleNewDocumentClick}
        />
        <MetadataExportButton isCompactView={isCompactView} />
        <BulkUpdateButton
          isCompactView={isCompactView}
          isSuperAdmin={isSuperAdmin}
        />
      </ContextMenuButtonGroupDiv>
      <ContextMenuButtonGroupDiv>
        <ContextMenuButtonGroupSpan id="density-columns-span">
          <RowDensity isCompactView={isCompactView} />
          <ColumnVisibility isCompactView={isCompactView} />
        </ContextMenuButtonGroupSpan>
        <ExpandCollapseGroup isCompactView={isCompactView} />
        <ResetView
          onClickHandler={handleResetView}
          isCompactView={isCompactView}
          disabled={isWindowTooSmall}
        />
        <SelfHelpButtonControl
          section={SectionName.DocumentListMenu}
          currentSelfHelp={CurrentSelfHelp.DocumentListMenu}
          page={SelfHelpPage.List}
        />
      </ContextMenuButtonGroupDiv>
    </>
  );
}
